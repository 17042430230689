import { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { Menu, MenuItem, Typography, Dialog, DialogContent } from '@mui/material';
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import { Link, useLocation } from "react-router-dom";

function PrivacyDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const { search } = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);

  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#011842",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ marginTop: "4%", textAlign: "left" }}>
        <Container>
          <Link to={`/${search}`} style={{ textDecoration: "none" }}>
            <Typography
              color="#464646"
              sx={{ marginTop: "10px", fontWeight: 300 }}
              variant="subtitle1"
            >
              &lt; Atrás
            </Typography>
          </Link>
          <Typography sx={{ marginTop: "10px" }} variant="h4">
            Política de privacidad
          </Typography>
          <Typography sx={{ marginTop: '20px' }} variant="h5">1. Responsable del tratamiento de los datos</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">El responsable del tratamiento es la persona jurídica que determina los fines y medios del tratamiento de Datos Personales. En otras palabras, el responsable decide cómo y con qué finalidades se tratan los Datos Personales.</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">A los efectos de la presente Política de Privacidad, el responsable del tratamiento de los Datos Personales es:</Typography>
          <ul>
            <li>
              <Typography variant="body2">SWIDRILE, SL.</Typography>
            </li>
            <li>
              <Typography variant="body2">CIF: B-70393889</Typography>
            </li>
            <li>
              <Typography variant="body2">Dirección postal: C/ García Sabell, 1-Edificio Proa-Plantas 7 y 8, 15009 (A Coruña)</Typography>
            </li>
            <li>
              <Typography variant="body2">Correo electrónico: lopd@swidrile.com</Typography>
            </li>
          </ul>
          <Typography variant="body2">
            A estos efectos, se hace constar que SWIDRILE, S.L. es la empresa matriz del grupo empresarial “BSD DIGITAL GROUP”. Puedes consultar el listado de empresas que forman parte del grupo empresarial haciendo click{' '}
            <Typography
              component="span"
              variant="subtitle5"
              onClick={handleClickAnchor}
              sx={{
                textDecoration: 'underline',
                color: 'blue',
                cursor: 'pointer',
                display: 'inline',
              }}
            >
              aquí
            </Typography>.
          </Typography>
          <Menu
            anchorEl={anchorEl}
            open={openAnchor}
            onClose={handleCloseAnchor}
          >
            <MenuItem onClick={handleCloseAnchor}><Typography variant="body2">Bysidecar, S.L.</Typography></MenuItem>
            <MenuItem onClick={handleCloseAnchor}><Typography variant="body2">Lacorde nuevas tecnologías S.L.</Typography></MenuItem>
            <MenuItem onClick={handleCloseAnchor}><Typography variant="body2">BSD Digital Insurance S.L.</Typography></MenuItem>
            <MenuItem onClick={handleCloseAnchor}><Typography variant="body2">Contratar online S.L.</Typography></MenuItem>
          </Menu>

          <Typography sx={{ marginTop: '20px' }} variant="h5">2. Objeto de la Política de Privacidad</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">El objeto de la presente política de privacidad es informarle de las normas que regirán el tratamiento de los datos de carácter personal que hayan facilitado a BSD DIGITAL GROUP mediante la web www.contratarelectricidad.es (en adelante “La Web”).</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Mediante esta Política de Privacidad nuestra intención es informar a los usuarios sobre la recopilación y tratamiento de datos personales que lleva a cabo el responsable de la Web, los fines para los que utilizamos los datos y las formas en que se gestionan tales datos, en estricto cumplimiento con el principio de transparencia en virtud de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (“LOPDGDD”), así como lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y libre circulación de estos datos ("RGPD").</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">A estos efectos, el responsable del tratamiento de los datos personales es BSD DIGITAL GROUP y los datos de contacto se encuentran recogidos en la Sección de Identificación del responsable de esta Política de Privacidad.</Typography>

          <Typography sx={{ marginTop: '20px' }} variant="h5">3. ¿De qué fuentes obtenemos sus datos personales?</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">BSD DIGITAL GROUP podrá obtener tus datos personales a través de las siguientes modalidades:</Typography>
          <ul>
            <li>
              <Typography variant="body2"><strong>A través del propio usuario</strong>: el usuario puede facilitarnos sus datos personales al solicitar información de los productos y/o servicios ofertados en nuestro sitio web, o al ponerse en contacto con nosotros por medio de los formularios de contacto existentes en el sitio web o por vía telefónica con un número ofrecido por BSD DIGITAL GROUP en nuestro sitio web para solicitar cualquier tipo de consulta o duda.</Typography>
              <Typography variant="body2">Estos datos podrán ser utilizados para prestar el servicio solicitado o cualquier acción necesaria para la gestión de los mismos o, en su caso, para poder enviarle información técnica y/o comercial de productos o servicios solicitados por el usuario de proveedores con los que colabore BSD DIGITAL GROUP.</Typography>
              <Typography variant="body2">El usuario garantiza que los datos personales facilitados a BSD DIGITAL GROUP son veraces y se hace responsable de comunicar a ésta cualquier modificación de los mismos, siendo responsable de la información falsa o inexacta que realice y de los perjuicios que cause a BSD DIGITAL GROUP.</Typography>
            </li>
            <li>
              <Typography variant="body2"><strong>Navegación del sitio web:</strong> también recopilamos datos analíticos en función de las acciones que realice el usuario a través de la web, esto es, sobre cómo se utiliza nuestro sitio web, para así poder ofrecer un mejor servicio a los usuarios. Esta información se recaba por medio de cookies de manera agregada, y según las opciones que el usuario marque. Puede obtener más información consultando nuestra <a href="https://www.contratarelectricidad.es/cookies" target="_blank">Política de cookies</a></Typography>
            </li>
          </ul>

          <Typography sx={{ marginTop: '20px' }} variant="h5">4. ¿Cuál es la legitimación para tratar sus datos personales?</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">De conformidad con la normativa vigente en materia de protección de datos, estamos legitimados al tratamiento de sus datos personales en los siguientes supuestos:</Typography>
          <ul>
            <li>
              <Typography variant="body2"><strong>Consentimiento del interesado (art. 6.1.a) RGPD)</strong>: el consentimiento expreso e inequívoco del usuario es la base principal para que BSD DIGITAL GROUP pueda tratar sus datos personales para enviarle comunicaciones comerciales o información acerca de los servicios y productos que Vd. solicita.</Typography>
              <Typography variant="body2">El consentimiento del usuario se obtiene a través de la marcación de las casillas de aceptación del tratamiento de datos correspondiente en los que el usuario facilita su número de teléfono o correo electrónico para que BSD DIGITAL GROUP se ponga directamente en contacto con el usuario, o bien mediante los servicios click to call en virtud de los cuales el usuario puede realizar llamadas directamente a BSD DIGITAL GROUP para solicitar información de los productos y/o servicios propios o de terceros ofertados.</Typography>
              <Typography variant="body2">Respecto a aquella información que sea remitida por menores de edad, será requisito imprescindible que el remitente haya obtenido previamente el consentimiento paterno, del tutor o del legal representante para que los datos de carácter personal puedan ser objeto de tratamiento automatizado.</Typography>
            </li>
            <li>
              <Typography variant="body2"><strong>Relación contractual (art. 6.1.b) RGPD)</strong>: el tratamiento es necesario para la gestión y tramitación contractual entre el usuario y BSD DIGITAL GROUP, o bien entre el usuario y las entidades para las que BSD DIGITAL GROUP colabora como intermediario.</Typography>
            </li>
            <li>
              <Typography variant="body2"><strong>Interés legítimo (art. 6.1.f) RGPD)</strong> también podremos tratar sus datos para nuestros intereses comerciales, respetando en todo caso los derechos que le asisten en materia de protección de datos.</Typography>
            </li>
          </ul>

          <Typography sx={{ marginTop: '20px' }} variant="h5">5. ¿Cuáles son las finalidades del tratamiento?</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Los datos personales que Vd. facilita a BSD DIGITAL GROUP tienen como finalidad.</Typography>
          <ul>
            <li>
              <Typography variant="body2">Gestionar las comunicaciones recibidas a través del correo electrónico o del formulario de contacto, o a través del click to call.</Typography>
            </li>
            <li>
              <Typography variant="body2">Enviarle información por cualquier medio, incluido el correo electrónico y/o medio equivalente, sobre servicios adecuados y personalizados que Vd. solicite en cada momento.</Typography>
            </li>
            <li>
              <Typography variant="body2">Mantener y desarrollar la relación comercial existente</Typography>
            </li>
            <li>
              <Typography variant="body2">Mantenerle informado, por cualquier medio de comunicación electrónica, de aquellas ofertas, novedades y promociones comerciales de terceras entidades, incluyendo el análisis y la formación de perfiles y, en general, la realización de acciones comerciales, de promoción y/o marketing relacionadas con los servicios que publicitamos o relacionados con proveedores de transportes, telecomunicaciones, alimentación, actividad energética, deportes, seguros, servicios inmobiliarios, financieros y bancarios así como de gran consumo, alimentación y retail, con el fin de ofrecerle sus productos o servicios y realizar ofertas comerciales, siempre y cuando Vd. haya prestado su consentimiento expreso autorizando el envío de dichas comunicaciones comerciales.</Typography>
            </li>
            <li>
              <Typography variant="body2">Aquellas actividades propias del objeto social del responsable del tratamiento.</Typography>
            </li>
            <li>
              <Typography variant="body2">Análisis de la experiencia del usuario y de nuestro servicio.</Typography>
            </li>
            <li>
              <Typography variant="body2">Tratamientos para cumplimiento de obligaciones legales.</Typography>
            </li>
            <li>
              <Typography variant="body2">Recogida y tratamiento automatizado de datos personales como consecuencia de la navegación en nuestras páginas web (ver <a href="https://www.contratarelectricidad.es/cookies" target="_blank">Política de cookies</a>.</Typography>
            </li>
          </ul>

          <Typography sx={{ marginTop: '20px' }} variant="h5">6. ¿A quiénes comunicaremos tus datos?</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Para cumplir con las finalidades señaladas en la presente Política de Privacidad, es necesario que compartamos sus datos personales a entidades pertenecientes al grupo empresarial del que forma parte BSD DIGITAL GROUP, y cuya identificación se encuentra en el listado que aparece en el primer apartado de la presente política, así como a terceras entidades (destinatarios) en el supuesto de que:</Typography>
          <ul>
            <li>
              <Typography variant="body2">Usted haya proporcionado su consentimiento explícito para uno o más fines específicos según el art. 6.1.a) del RGPD.</Typography>
            </li>
            <li>
              <Typography variant="body2">La divulgación según el art. 6.1.f) del RGPD sea necesaria para la aseveración, ejercicio o defensa de reclamaciones judiciales.</Typography>
            </li>
            <li>
              <Typography variant="body2">Para el caso en el que exista una obligación legal para la divulgación según el art. 6.1.c) del RGPD. </Typography>
            </li>
            <li>
              <Typography variant="body2">Sea legalmente permisible y necesario para cumplir con las obligaciones contractuales según el art. 6.1.b) del RGPD.</Typography>
            </li>
          </ul>
          <Typography sx={{ marginTop: '10px' }} variant="body2">En aquellos supuestos en los que BSD DIGITAL GROUP contrate los servicios de terceros, sus datos personales podrán ser tratados por un encargado del tratamiento. No se producirá la transmisión de sus datos personales con fines distintos a los enumerados anteriormente.
            <br />Sin perjuicio de lo anterior, le informamos que las referidas entidades se encuentran ubicadas en el Espacio Económico Europeo, por lo que la transferencia de datos personales estará bajo un nivel de protección de datos seguro y garantizando la confidencialidad de los mismos.
            <br />Asimismo, se informa al usuario que, si proporciona datos adicionales de manera directa a las compañías de las que ofertamos productos y/o servicios, o si decide contratar los productos y/o servicios de éstas, serán las referidas compañías las responsables de los datos aplicando directamente sus políticas de privacidad, recomendándole que las consulte previamente.</Typography>

          <Typography sx={{ marginTop: '20px' }} variant="h5">7. Sitios web de terceros</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Nuestra página web contiene enlaces a otros sitios web, por lo que Vd. debe tener presente que, si hace clic en un enlace a un sitio web de terceros, Vd. será redirigido a páginas que BSD DIGITAL GROUP no controla.</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Le instamos consulte la política de privacidad de esos sitios web, ya que sus procedimientos de recogida, tratamiento y conservación de datos personales pueden diferir de los de nuestras entidades.</Typography>

          <Typography sx={{ marginTop: '20px' }} variant="h5">8. Derechos del usuario</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">El Usuario podrá revocar el consentimiento prestado y ejercer su derecho de acceso, rectificación, cancelación y oposición, portabilidad y supresión al tratamiento de sus datos personales, en los términos y condiciones previstos en la normativa vigente en materia de protección de datos de carácter personal</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Para su mayor comodidad, podrá ejercer los derechos antes mencionados dirigiéndose a BSD DIGITAL GROUP: A/A Departamento de protección de datos BSD DIGITAL GROUP, C/ García Sabell, 1-Edificio Proa-Plantas 7 y 8, 15009 (A Coruña) o bien a nuestra dirección de correo electrónico: <a href="mailto:lopd@swidrile.com">lopd@swidrile.com</a></Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Asimismo, se informa a los Usuarios que tienen derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (<a href="https://www.aepd.es">www.aepd.es</a>) en caso de que consideren vulnerados sus derechos.</Typography>

          <Typography sx={{ marginTop: '20px' }} variant="h5">9. Conservación de sus datos</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">BSD DIGITAL GROUP únicamente conservará y tratará los datos de los Usuarios mientras permanezcan las finalidades para las que han sido recabados y, en cualquier caso, hasta el vencimiento de los plazos legalmente establecidos.</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">El tratamiento de los datos para la remisión de comunicaciones informativas se realizará mientras el Usuario no revoque su consentimiento y mientras permanezcan inalterados los fines para los cuales se han recabado dichos datos de carácter personal.</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Posteriormente, se procederá al bloqueo de los datos de carácter personal, que permanecerán a disposición exclusiva de los jueces y tribunales, el Ministerio Fiscal o las autoridades competentes para la exigencia de posibles responsabilidades nacidas del tratamiento, durante el plazo de prescripción de las mismas. Cumplido el citado plazo, BSD DIGITAL GROUP procederá a la supresión de los datos.</Typography>

          <Typography sx={{ marginTop: '20px' }} variant="h5">10. También recibimos información técnica cuando usa nuestros servicios</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Actualmente cuando usa un sitio web, una aplicación móvil u otro servicio de Internet, casi siempre se crea y se registra determinado tipo de información de manera automática.</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">A continuación, le informamos de algunos tipos de información que recopilamos cuando Vd. Utiliza este sitio web:</Typography>
          <ul>
            <li>
              <Typography variant="body2"><strong>Datos de registro</strong>: Cuando navegas por nuestra página web nuestros servidores registran automáticamente información (“datos de registro”), que incluye los datos que envía su navegador cada vez que visitas un sitio web.  Estos datos de registro pueden incluir tu dirección de protocolo de Internet, las direcciones de las páginas web que hayas visitado y que tenían contenidos de SWIDRILE SL, el tipo de navegador y su configuración, la fecha y hora de la petición, como utilizaste la web y los datos contenidos en cookies.</Typography>
            </li>
            <li>
              <Typography variant="body2"><strong>Datos de cookies</strong>: Según el modo en que accedas a nuestros productos, podríamos usar cookies (“un pequeño archivo de texto que envía tu ordenador cada vez que visitas nuestro sitio web”), o bien alguna tecnología similar para almacenar datos de registro. Cuando usamos cookies, pueden ser de “sesión” (permanentes activas hasta que se cierra el navegador) o “persistentes” (están activas hasta que Vd. o el navegador las borra). Un ejemplo, podríamos usar cookies para almacenar tus preferencias de idioma u otros ajustes, de forma que no sea necesario configurarlos cada vez que visites la Web.</Typography>
            </li>
            <li>
              <Typography variant="body2"><strong>Información del dispositivo</strong>: Además de registrar datos, también podríamos recopilar información acerca del dispositivo con el que accedes a la Web, como el tipo, su configuración, su identificación única, el sistema operativo que utilizas o datos sobre bloqueos. La recopilación de este tipo de información suele depender del tipo de dispositivo que utilices y de su configuración. La información es diferente si utilizas un Mac, un PC, un Iphone o un teléfono Android. Para saber el tipo de información que nos envía tu dispositivo, puedes consultar las políticas del fabricante del dispositivo o del proveedor de software.</Typography>
            </li>
          </ul>

          <Typography sx={{ marginTop: '20px' }} variant="h5">11. Seguridad</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">BSD DIGITAL GROUP adopta los niveles de seguridad requeridos por la normativa vigente en materia de protección de datos en cada momento, es decir, las medidas necesarias para evitar la alteración, pérdida, tratamiento o acceso no autorizado a los datos personales, habida cuenta en todo momento del estado de la tecnología. No obstante lo anterior, las medidas de seguridad en Internet no son inexpugnables y pueden existir filtraciones por actuaciones dolosas de terceros.</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Respecto a cualquier tipo de duda o controversia relativa a nuestra política de privacidad de datos puede ponerse en contacto con nosotros dirigiéndose a cualquiera de las direcciones postales y/o electrónicas indicadas en el apartado de identificación de los responsables del tratamiento.</Typography>

          <Typography sx={{ marginTop: '20px' }} variant="h5">12. Modificaciones de la Política de Privacidad</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">BSD DIGITAL GROUP podrá cambiar periódicamente esta política y, en tal caso, publicaremos dichos cambios en esta página web. El hecho de continuar navegando por la Web después de la entrada en vigor de los referidos cambios se entenderá como su aceptación de la política así modificada. En caso de que los cambios sean de una mayor importancia, lo notificaremos de manera más destacada o solicitaremos su consentimiento, tal y como especifica la ley.</Typography>

          <Typography sx={{ marginTop: '20px' }} variant="h5">13. Ley Aplicable y Fuero</Typography>
          <Typography sx={{ marginTop: '10px' }} variant="body2">Este Sitio Web y las condiciones contenidas en esta Política de Privacidad se rigen por la legislación española. Para cualquier cuestión litigiosa o conflicto derivado o relacionado con el acceso y la utilización del Sitio Web, o de la interpretación y cumplimiento de las mencionadas condiciones las partes se someten a la jurisdicción y competencia de los Juzgados y Tribunales de la ciudad de A Coruña.</Typography>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

export default PrivacyDialog;
